import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay]);

export default function sliders() {
    (function($) {
        $(window).on('load', function () {
           // get all sliders, we need to loop them due to different settings + nav
           var swipers = document.querySelectorAll('.reviews-swiper');
           swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');

                // slider settings
                var options = {
                    speed: 500,
                    loop: true,
                    //autoplay: {
                        //delay: 5000,
                        //disableOnInteraction: true,
                    //},
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    freeMode: true,
                    centeredSlides: true,
                    slidesPerView: 1,
                    spaceBetween: 30,
                    autoHeight: true,
                    on: {
                        slideChange: (swiper) => {
                            let image =
                            swiper.slides[
                            swiper.activeIndex
                            ].querySelector('img[data-src]')
                            if (image) {
                                loadSlideImage(image);
                            }
                        },
                    },

                    breakpoints: {
                      640: {
                        spaceBetween: 80,
                        slidesPerView: 1,
                    },
                    992: {
                        spaceBetween: 150,
                        slidesPerView: 1,
                    },
                    1172: {
                        spaceBetween: 234,
                        slidesPerView: 1,
                    },
                },
            };

                // init slider
                new Swiper(el, options);
            });
       })

    })( jQuery );

    (function($) {
        $(window).on('load', function () {
           // get all sliders, we need to loop them due to different settings + nav
           var swipers = document.querySelectorAll('.logos-swiper');
           swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');

                 // slider settings
                var options = {
                    loop: true,
                    autoHeight: true,
                    autoplay: {
                        delay: 1800,
                        disableOnInteraction: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    freeMode: true,
                    slidesPerView: 3,
                    spaceBetween: 20,
                    on: {
                        slideChange: (swiper) => {
                            let image =
                            swiper.slides[
                            swiper.activeIndex
                            ].querySelector('img[data-src]')
                            if (image) {
                                loadSlideImage(image);
                            }
                        },
                    },

                    breakpoints: {
                        640: {
                            spaceBetween: 10,
                            slidesPerView: 3,
                        },
                        992: {
                            spaceBetween: 10,
                            slidesPerView: 6,
                        },
                        1172: {
                            spaceBetween: 10,
                            slidesPerView: 7,
                        },
                },
            };

                // init slider
                new Swiper(el, options);
            });
       })

    })( jQuery );

    (function($) {
        $(window).on('load', function () {
           // get all sliders, we need to loop them due to different settings + nav
           var swipers = document.querySelectorAll('.logos-swiper-wide');
           swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');

                 // slider settings
                var options = {
                    loop: true,
                    autoHeight: true,
                    centeredSlides: true,
                    autoplay: {
                        delay: 1800,
                        disableOnInteraction: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    freeMode: true,
                    slidesPerView: 2,
                    spaceBetween: 20,
                    on: {
                        slideChange: (swiper) => {
                            let image =
                            swiper.slides[
                            swiper.activeIndex
                            ].querySelector('img[data-src]')
                            if (image) {
                                loadSlideImage(image);
                            }
                        },
                    },

                    breakpoints: {
                      640: {
                        spaceBetween: 10,
                        slidesPerView: 6,
                    },
                    992: {
                        spaceBetween: 10,
                        slidesPerView: 6,
                    },
                    1172: {
                        spaceBetween: 15,
                        slidesPerView: 9,
                         loop: true,
                    },
                },
            };

                // init slider
                new Swiper(el, options);
            });
       })

    })( jQuery );
}


function loadSlideImage(image) {
	let src = image.getAttribute('[data-src]')
	let sourceSet = image.parentNode.querySelectorAll('[data-srcset]')
	if (sourceSet.length > 0) {
		for (let i = 0; i < sourceSet.length; i++) {
			sourceSet[i].srcset = sourceSet[i].getAttribute('data-srcset')
		}
	}
	if ('img' === image.tagName.toLowerCase()) {
		if (src) {
			image.src = src
		}
	} else {
		image.style.backgroundImage = "url('" + src + "')"
	}
}